.details-container {
    display: flex;
    flex-wrap: wrap;
}

.table-container {
    flex: 1 1 60%; /* 70% of the container's width */
    box-sizing: border-box;
    padding: 10px;
    
}

.detail-list {
    flex: 1 1 40%; /* 30% of the container's width */
    box-sizing: border-box;
    padding: 10px;
}

// .table-list-view {
//     width: 100%; /* Ensure the ListView takes up the full width of its container */
    
// }

.list-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;    
}

@media (max-width: 768px) {
    .table-container,
    .detail-list {
      flex: 1 1 100%;
      margin-top: 0;
    }
  }
  