.table table{
    width:calc(100% - 40px);
}
.details-container {
    // display: flex;
    // flex-wrap: wrap;
    display: block;
    width: 100%;
    overflow: hidden;
}

.table-container {
    // flex: 1 1 60%; /* 70% of the container's width */
    // box-sizing: border-box;
    // padding: 10px;
    display: block;
    width: 50%;
    float: left;
    
}

.detail-list {
    // flex: 1 1 40%; /* 30% of the container's width */
    // box-sizing: border-box;
    // padding: 10px;
    display: block;
    float: left;
    width: 50%;
    margin-top: 20px;
}

// .table-list-view {
//     width: 100%; /* Ensure the ListView takes up the full width of its container */
    
// }
.table-list-view .TableLayout{
    width:1080px;
}

.list-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;    
}

@media (max-width: 768px) {
    .table-container,
    .detail-list {
      flex: 1 1 100%;
      margin-top: 0;
    }
  }
  