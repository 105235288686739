.commodity-tabs {
    display: flex; /* Use Flexbox for layout */
    gap: 10px;
    flex-direction: column;
}

.commodity .accordion {
    background-color: rgb(235, 235, 241);
}
.producer-accordion .accordion {
    background-color: rgb(183, 183, 190);
    width: 90%;
    margin: 0 auto
}
.production-Place .accordion{
    background-color: rgb(235, 235, 241);
    width: 1300px;
    margin-right: 10px;
    margin-left: 30px;
}
.quantity-tabs {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  
  .quantity-tabs > * {
    margin-right: 10px; /* Adjust spacing between elements as needed */
  }

.hs-heading {
    display: flex; /* Use Flexbox for layout */
    gap: 10px;
    flex-direction: row;
}
.description{
    width: 100%;
}
.production-place{
    display: flex; /* Use Flexbox for layout */
    gap: 10px;
    // flex-direction: row;
}