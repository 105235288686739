
  .pending-container
  {
    .btn--small{
    display: flex;
    justify-content: flex-end !important;
    position: relative;
    width: auto;
    z-index: 3;
    }
     >.btn--small{
      float: right;
      top: 50px;
    }
  }
  