.product-tabs {
  display: flex; /* Use Flexbox for layout */
  align-items: center; /* Align items vertically center */
  gap: 10px; /* Add space between items */
}
.radio-button-group{
  gap: inherit;
  display: flex;
  margin-top: 1rem;
}
.products-container {
  position: relative; /* Positioning context for absolute positioning */
}

.header {
  display: flex; /* Flex container for header elements */
  justify-content: space-between; /* Space between tabs and button */
  align-items: center; /* Vertically center items */
  margin-bottom: 20px; /* Space below header */
}

.top-right-button {
  margin-left: auto; /* Push the button to the right */
}
.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-row > * {
  margin-right: 10px;
}

.product-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.accordion__item {
  overflow: hidden !important;
  transition: max-height 0.3s ease !important;
}

.accordion__item--active > .accordion__content {
  max-height: none !important;
  height: auto !important;
}

.accordion__content {
  max-height: 0 !important;
  overflow: hidden !important;
  transition: max-height 0.3s ease !important;
}

// .accordion-title {
//   flex-grow: 1;
// }
// .maindiv {
//   display: flex;
//   align-items: center;
// }


.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.radio-group {
  display: flex;
  align-items: center;
}


