.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Allow the table to adjust based on content */
  box-sizing: border-box; /* Include padding and borders in the width */
  cursor: pointer;
  .highlighted-row {
    background-color: #cce5ff !important ; /* Light blue background */
    color: #004085; /* Darker text for contrast */
  }
}

.table th, .table td {
  border: 1px solid #ccc;
  padding: 4px;
  text-align: left;
  word-wrap: break-word;
  white-space: normal; /* Text wraps within the cell */
  font-size: small;
}

.table th {
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pill--xsmall {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  min-height: 2rem;
  min-width: 2rem;
  padding: 0;
}

/* Make sure the parent container doesn't trigger overflow */
.parent-container {
  overflow-x: hidden; /* Prevent unnecessary horizontal scrolling */
}
.theatre__content-wrapper{
  margin-left: 10px;
}
.pagination-button {
  margin: 0 5px;
  border: none;
  background: none;
}
.pagination-number {
  margin: 0 5px;
  border: none;
  background: none;
  text-decoration: underline;
}

