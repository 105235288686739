.display-details {
    width: 100%;
    border: 2px solid; /* Set a light grey border */
    border-radius: 5px; /* Rounded corners for a softer look */
    padding: 4px; /* Add padding inside the border */
    margin-bottom: 20px; /* Space below the section */
    // background-color: #f9f9f9; /* Optional: light background color for contrast */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
}

